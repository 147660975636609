<template>
  <div class="flex items-center">
    <button
        type="button"
        class="btn-primary"
        :disabled="synchronization"
        @click="synchronize()"
    >
      Synchronize
    </button>
    <BeatLoader class="flex-1 ml-4" :loading="synchronization"/>
    <span class="px-4" v-if="!synchronization && status && timestamp">
      <div class="text-sm text-red-600" :class="{'text-green-600': status === 'Success'}">{{status}}</div>
      <div class="text-xs">{{timestamp}}</div>
    </span>
  </div>
</template>

<script>
  import moment from 'moment';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import BeatLoader from "@/components/ui/BeatLoader";

  export default {
    name: 'ApplicationCheckConnectivityButton',
    components: {BeatLoader},
    data: function () {
      return {
        synchronization: false,
        status: undefined,
        timestamp: undefined,
      };
    },
    mixins: [
      NotifyMixin,
    ],
    props: {
      customerId: {
        type: String,
        required: true,
      },
    },
    emits: ['synchronized'],
    methods: {
      async synchronize() {
        if (this.synchronization) {
          return;
        }

        this.synchronization = true;

        let success = false;

        try {
          const permsResult = await this.$authDataProvider.get('synchronizePermissions', {customerId: this.customerId});
          const rolesResult = await this.$authDataProvider.get('synchronizeRoles', {customerId: this.customerId});
          if (permsResult.success && rolesResult.success) {
            this.$emit('synchronized');
            success = true;
          }
        } catch (error) {
          this.notifyError(error.message);
        } finally {
          this.synchronization = false;
        }

        this.status = success ? 'Success' : 'Error';
        this.timestamp = moment().format('LLL');
      }
    }
  };
</script>
